body {
  background-color: #f0f0f0;
}

.content-card {
  margin: 25px;
  padding: 20px;
  text-align: center;
  justify-content:flex-end;
  align-items: center;
}

.content-card-container {
  background-color: #0008;
  height: 100%;
  padding-top: 20px;
}

.footer-middle {
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: "center";
  justify-content: center;
  font-size: 0.8em;
  color: white;
}

.footer-left {
  display: "flex";
  align-items: "center";
  padding-left: 3;
  font-size: 0.8em;
  color: white;
}

.footer-right {
  display: "flex";
  justify-content: "right";
  align-items: "center";
  width:"fitContent";
  position: absolute;
  right: 10px;
  bottom: 0px;
  height:100%;
  max-width: 100%;
  max-height: 100%;
}

.footer {
  background-color: #2196f3;
  position: sticky; 
  bottom: 0;  
  width: 100%; 
  padding: 3px; 
  text-align: center; 

}

.login-root {
  background-color:#2196f3;
  position: flex;
  width: 450px;
  padding: 15px;
}

.icon-button {
  display: flex;
  flex-direction: column;
}

.icon-button-label {
  display: flex;
  flex-direction: column;
  font-size: small;
  font-weight: normal;
  color: white;
}

.fab-register {
  position: absolute !important;
  top: -40px;
  right: 50px;
}

.list-stack {
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    margin: auto;
}

.icon-list {
  color: white;
}

.countdown-value {
  font-size: 3em;
  font-weight: bold;
  color: white;
  font-family: arial;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  padding-bottom: 15px;
  background-color: #0008;
  border-bottom-color: #7c7c7c;
  border-right-color: #7c7c7c;
}

.countdown-label {
  font-size: 0.8em;
  color:#afe6ff;
  position: relative;
  top: -23px;
}

.party-stats-chart.MuiChartsAxis-tickContainer {
  color: #afe6ff;
}

@media only screen and (max-width: 600px) {
  .countdown-value {
    font-size: 2em;
    font-weight: bold;
    color: white;
    font-family: arial;
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    padding-bottom: 15px;
    background-color: #0008;
    border-bottom-color: #7c7c7c;
    border-right-color: #7c7c7c;
  }
  
  .countdown-label {
    font-size: 0.5em;
    color:#afe6ff;
    position: relative;
    top: -23px;
  }
}